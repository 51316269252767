@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@200;300;400;700&display=swap');


$base-color: #CEC9A3;
$link-color: #4be0e0;
$mouse-scroll: white;

// hero

*, *:before, *:after {
  box-sizing: border-box;
}


.header {
  // box
  width: 100%;
  min-height: calc(100vh - 65px);

  .heroTitle {
    font-style: normal;
    font-weight: normal;
    color: whitesmoke;
    text-align: left;
    float: left;
    position: absolute;
    bottom: 120px;
    left: 50px;

    h2 {
      font-size: 125px;
      color: whitesmoke;
      line-height: 45px;
      font-family: 'Yanone Kaffeesatz', sans-serif;
      font-weight: 900;
    }

    p {
      color: whitesmoke;
      font-family: 'Yanone Kaffeesatz', sans-serif;
      font-size: 25px;
    }

    a {
      color: white;
    }

    span {
      font-size: 20px;
    }
  }

  // bg
  background: rgba(234, 232, 212, 1);
  background: linear-gradient(30deg, rgb(49, 51, 59) 0%, rgb(49, 51, 59) 50%, rgba(255, 255, 255, 1) 50%, rgba(234, 232, 212, 1) 50%, rgba(234, 232, 212, 1) 100%);
  margin-bottom: 150px;

  img {
    max-width: 100%;
    height: auto;
  }
}

.grid-temp {
  display: grid;
  place-items: center;
  height: 400px;

  h2 {
    color: rgba(234, 232, 212, 1);
  }
}

@media (max-width: 991px) {
  .header {
    min-height: 0;
    height: 800px;
  }

  .heroIMG {
    position: absolute;
    bottom: 150px;
    text-align: left;
    width: 900px;
  }


  .heroTitle {
    position: absolute;
    top: 150px;
    right: 30px;
    left: 0;
    text-align: right !important;

    h2, p, span, a {
      color: black !important;
    }
  }
}

@media (max-width: 665px) {
  .heroIMG {
    position: absolute;
    bottom: 150px;
    text-align: left;
    width: 500px;
  }
}

@media (max-height: 725px ) and (max-width: 455px) {
  .heroIMG {
    bottom: 0;
  }

  .heroTitle {
    top: 80px;

    span, a {
      padding: 10px 5px;
    }

    h2 {
  margin: 20px 0;
    }
  }
}

// timeline
.ant-layout-content {
  margin: 0 auto;
}

.timeline {
  max-width: 1024px;
  color: $base-color !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .ant-image {
    opacity: .2;

    h3 {
      text-align: left;
      font-family: 'Yanone Kaffeesatz', sans-serif;
    }

  }

  .ant-image-img {
    border-radius: 70% 30% 50% 10%;
  }

  .customTick {
    position: absolute;
    top: -2px;
    left: -4px;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border: 2px solid rgb(206, 201, 163);
    border-radius: 100px;
  }

  .customDate {
    position: absolute;
    top: 0;
    right: 30px;
    font-size: 50px;
    color: $base-color;
  }

  .timelineCard {
    max-width: 700px;

    p {
      color: white;
    }

    button {
      background: #1f69e0;
      color: white;
    }
  }

  .ant-image {
    max-width: 600px;
    float: right;
    position: absolute;
    top: 50px;
    z-index: -5;
  }

  .ant-timeline-item-content {
    height: 60vh;
  }

  .timelineBox_Box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .timelineBox {
    position: relative;

    img {
      text-align: center;
      margin: 0 auto;
    }
  }

  .timelineBox_content {
    max-width: 600px;
  }

  .timeline-date {
    z-index: 1200;
    font-size: 120px;
  }

  h3 {
    font-size: 55px;
    text-align: left;
    color: $base-color !important;
    font-family: 'Yanone Kaffeesatz', sans-serif;
  }

  button {
    background: none;
    border: none;
    border-bottom: 1px solid #F6F5DC;
  }
}

// table

.tableProduct_wrapper {
  background: $base-color;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 800px;
  color: black;
  padding: 50px 20px;

  .ant-divider {
    span {
      color: black;
    }
  }

  h1 {
    font-size: 35px;
    color: $base-color;
  }

  a {
    color: white;
  }
}

.tableProduct {
  position: relative;
  font-family: Lato, sans-serif !important;
  margin: 100px auto;

  .tableDesc_Object {
    position: absolute;
    right: -30px;
    bottom: -20px;
    background: rgba(215, 204, 172, 0.87);
    padding: 20px;

    p {
      color: white;
      font-weight: bold;
    }
  }
}

.tableProduct_desc {
  margin: 50px 0;
}

.tableDesc {
  position: absolute;
  top: 0;
  left: -150px;
  background: rgba(215, 204, 172, 0.87);
  padding: 20px;
}

.article {
  background: white;
  padding: 20px;
  margin: 0 auto;
  max-width: 700px;

  a {
    margin: 0 5px;
  }

  h1, h2, h3, h4 {
    color: dimgrey;
  }

  h1 {
    font-size: 45px;
  }

  h4 {
    font-size: 25px;
  }

  p {
    color: #171717;
    font-size: 16px;
    line-height: 1.25em;
    padding: 0 0 1em 0;
    text-align: justify;
  }

}

.logo-text {
  color: black;
  font-weight: bold;
  margin-right: 10px;
}

@media (max-width: 991px) {
  .timeline {
    h3 {
      font-size: 25px;
      text-align: left;
    }
  }

  .timelineCard {
    margin-top: 100px;
  }

  .customDate {
    left: 20px !important;
    top: 50px;
  }

  .img-header {
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .tableProduct {
    img {
      width: 100%;
    }
  }
}

@media (max-width: 1024px) {
  .tableDesc {
    left: 0;
  }

  .tableDesc_Object {
    right: 0;
    display: none;
  }
}

.ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: white !important;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  border-top: 1px solid rgb(255 255 255);
}

// mouse scroll

.mouse_scroll {
  position: absolute;
  right: 50px;
  bottom: 20px;
  display: block;
  margin: 0 auto;
  width: 24px;
  height: 100px;
  margin-top: 125px;
}


.m_scroll_arrows {
  display: block;
  width: 5px;
  height: 5px;
  transform: rotate(45deg);

  border-right: 2px solid $mouse-scroll;
  border-bottom: 2px solid $mouse-scroll;
  margin: 0 0 3px 4px;

  width: 16px;
  height: 16px;
}


.unu {
  margin-top: 1px;
}

.unu, .doi, .trei {
  animation: mouse-scroll 1s infinite;

}

.unu {

  animation-direction: alternate;
  animation-delay: alternate;
}

.doi {

  animation-delay: .2s;
  animation-direction: alternate;

  margin-top: -6px;
}

.trei {

  animation-delay: .3s;
  animation-direction: alternate;


  margin-top: -6px;
}

.mouse {
  height: 42px;
  width: 24px;
  border-radius: 14px;
  transform: none;
  border: 2px solid $mouse-scroll;
  top: 170px;
}

.wheel {
  height: 5px;
  width: 2px;
  display: block;
  margin: 5px auto;
  background: $mouse-scroll;
  position: relative;

  height: 4px;
  width: 4px;
  border: 2px solid $mouse-scroll;
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

.wheel {
  animation: mouse-wheel 0.6s linear infinite;
}

@-webkit-keyframes mouse-wheel {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(6px);
  }
}

@-moz-keyframes mouse-wheel {
  0% {
    top: 1px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: 1px;
  }
}

@-o-keyframes mouse-wheel {

  0% {
    top: 1px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: 1px;
  }
}

@keyframes mouse-wheel {

  0% {
    top: 1px;
  }
  25% {
    top: 2px;
  }
  50% {
    top: 3px;
  }
  75% {
    top: 2px;
  }
  100% {
    top: 1px;
  }
}

@-webkit-keyframes mouse-scroll {

  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes mouse-scroll {

  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes mouse-scroll {

  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes mouse-scroll {

  0% {
    opacity: 0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

.articleMenu {
  position: sticky !important;
  top: 0 !important;
  margin-bottom: 30px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
}

.blog {
  display: flex;
  justify-content: center;
  flex-direction: column;

  h1 {
    color: white;
    margin: 20px 0;
    font-size: 35px;
    font-weight: 300;
  }
}