.navbar {
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
    z-index: 100;
}

.menu-bars {
    font-size: 2rem;
    background: none;
    margin-left: 40px;

    svg {
        fill: #F95738;
    }

    :hover {
        color: #f06873 !important;
    }
}

.nav-menu {
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 100;
    background: #282827;
    box-shadow: 21px 0px 13px -20px rgba(66, 68, 90, 0.85);
}

@media (max-width: 575px) {
    .nav-menu {
        /*width: 100%;*/
    }
}

.nav-menu.active {
    left: 0;
    transition: 350ms;
}

.nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0 8px 16px;
    list-style: none;
    height: 60px;

    span {
        margin-left: 16px;
    }

    a {
        text-decoration: none;
        font-size: 18px;
        width: 95%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-radius: 4px;
        color: white;
    }

    a:hover {
        background-color: #4c4e51;
        color: white;
        font-weight: 400;
        border-bottom: 2px solid #c0bfb9;
        transition: .4s ease-in-out;
    }
}

.nav-menu-items {
    width: 100%;
}

.navbar-toggle {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
}