* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

.App {
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
}

p {
  font-family: Lato;
  line-height: 32px;
}

.ant-table {
  background: none !important;
  color: white !important;
}

.ant-layout {
  background: none !important;
  color: white;

  b {
    color: #eaeacd;
  }
}


body {
  background: #30333C !important;
}

.wrapper {
  margin: 20px auto;
  max-width: 1440px;
}

h3 {
  color: none;
}

.footer {
  background: #F95738;
  color: white;
  margin-top: 100px;

  a {
    color: white;
  }

  h3 {
    text-align: left !important;
    color: white;
    font-weight: 600;
    margin: 20px 0;
  }

  .row {
    padding: 50px;
  }

  .col-lg-12 {
    h1 {
      font-size: 128px;
      color: white;
      opacity: 0.1;
      position: absolute;
    }
  }

  img {
    width: 100%;
    max-width: 225px;
    height: auto;
    text-align: center;
  }
}

.topMenu {
  height: 62px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #EAE8D4FF;
  padding: 20px;

  a {
    color: white;
  }


  span {
    color: white;
  }
}

footer {
  ul {
    color: white !important;
  }
}

.ant-collapse-item {
  .ant-collapse-content {
    background-color: none;
    color: black;
  }

  p {
    color: black;
    margin: 0;
  }
}

.media {
  text-align: center;

  h1 {
    color: white;
    font-size: 35px;
  }

  h3 {
    color: white;
  }

  .mediaWrapper {
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    iframe {
      margin: 20px;
    }
  }
}

.siteBG {
  background: #121214;
}

.container {
  h3 {
    text-align: center;
  }

  .siteBG {
    padding: 20px;
  }
}

.gallery {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .galleryBox {
    max-width: 500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    img {
      margin: 10px;
    }
  }

  h3 {
    color: white;
  }
}

@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 0 !important;
  }
}